import { Button, ScreenWrapper } from "../components";
import styled from "@emotion/styled";

export default function HomeScreen({ setScreen }) {
  return (
    <ScreenWrapper>

      <ImagesWrapper>
        <PrizeWrapper>
          <img  src={"shirt.png"} alt={"prize"} />
        </PrizeWrapper>

        <BottleWrapper>
          <img
            className={"bottle-img"}
            src={"bottle.png"}
            alt={"bouteille de parfum boss"}
          />
        </BottleWrapper>
      </ImagesWrapper>

      <TextContent>
        <h1>
          Tentez de remporter
          <br />
          un tee-shirt BOSS signé par
          <br />
          Eduardo Camavinga
        </h1>
        <p>
          *participation dès l'achat d'une fragrance BOSS Bottled 100ML ou plus.
        </p>
        <Button
          text={"Je tente ma chance"}
          maxWidth={"240px"}
          dark={false}
          handleClick={() => setScreen({ name: "Email" })}
        />
      </TextContent>
    </ScreenWrapper>
  );
}

const ImagesWrapper = styled.div`
  position: relative;
  display: block;
  box-sizing: border-box;
  margin-bottom: 20px;
`;

const BottleWrapper = styled.div`
  --size: min(min(60vw, 190px), 20svh);

  width: var(--size);
  height: calc(var(--size) * 400/176);
  transform: translate3d(50%, 0, 0);

  @media screen and (max-width: 425px) and (min-aspect-ratio: 370/670) {
    --size: 17svh;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;

const PrizeWrapper = styled.div`
  --size: min(min(110vw, 520px), 50svh);

  position: absolute;
  top: 0;
  left: 50%;
  width: var(--size);
  height: var(--size);

  display: flex;
  align-items: center;
  justify-content: center;

  background: transparent url("./circle-neon.png") no-repeat center center;
  background-size: contain;

  transform: translate3d(-67%, -20%, 0);

  @media screen and (max-width: 425px) and (min-aspect-ratio: 370/670) {
    --size: 47svh;
  }

  img {
    width: 64%;
    height: 64%;
    object-fit: contain;
  }
`;

const TextContent = styled.div`
  margin-top: 20px;
  padding-bottom: 32px;
  
  h1 {
    font-size: 20px;
    font-family: Averta, sans-serif;
    margin-top: 0;
    margin-bottom: 24px;
    text-transform: uppercase;
  }

  p {
    font-size: min(10px, 2vw);
    text-transform: uppercase;
    margin-bottom: 24px;
  }

  @media screen and (max-width: 425px) and (min-aspect-ratio: 370/670) {
    margin-top: 0;

    h1 { 
      font-size: 5vw;
    }
  }
`;
