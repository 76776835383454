import { createUser, uploadFile } from "../requests";
import { getStoreItem, setStoreItem } from "../functions";
import { useEffect, useState } from "react";
import {
  Button,
  BlueBox,
  ScreenWrapper,
  FormField,
  CheckBox,
} from "../components";
import { useFormData } from "../hooks/useFormData";
import FileInput from "../components/FileInput";
import styled from "@emotion/styled";

export default function EmailScreen({ setScreen, params }) {
  // const [user, setUser] = useState(null);

  const [waitingValidation, setWaitingValidation] = useState(
    params?.waitingConfirmation || false
  );
  const [emailVerified, setEmailVerified] = useState("waiting");
  const [submitCnt, setSubmitCnt] = useState(0);
  const [formValues, handleFormValueChange, setFormValues] = useFormData({
    mail: "",
    file: null,
    conditions: false,
  });

  function isMailValid() {
    let mailExp = /\S+@\S+\.\S+/;
    return mailExp.test(formValues.mail);
  }

  // On init
  useEffect(() => {
    getStoreItem("@state").then((data) => {
      // Ajoute l'écran en cours dans le store
      setStoreItem("@state", { ...data, screen: "Email" });
    });
  }, []);

  // Envois de l'adresse email.
  const handleSubmit = () => {
    setSubmitCnt(1);
    // Sera utilisé pour annuler la création d'un utilisateur si l'email était déjà présent
    // dans la base de donnés.

    if (!isMailValid() || !formValues.conditions || !formValues.file) return;

    // Si le formulaire est valide
    getStoreItem("@state").then((data) => {
      setEmailVerified("yes");
    });
  };

  useEffect(() => {
    if (emailVerified === "yes") {
      if (waitingValidation) return;

      uploadFile(formValues.file).then((resFile) => {
        // Crée un utilisateur. Permet de lui envoyer un mail de confirmation.
        createUser(formValues.mail, resFile.path).then((data) => {
          if (data) {
            // Si l'utilisateur a bien été créé,
            // on enregistre l'information dans le local storage
            getStoreItem("@state").then((state) => {
              setStoreItem("@state", {
                ...state,
                waitingConfirmation: true,
              });
            });
          }
        });
        setWaitingValidation(true);
      });
    }
  }, [emailVerified]);

  return (
    <ScreenWrapper>
      {!waitingValidation && (
        <BlueBox>
          <div style={{ height: 16 }} />

          {/* Spacing */}
          <div style={{ height: 16 }} />

          <div className={"gb-small"}>
            RENSEIGNEZ VOTRE ADRESSE
            <br />
            E-MAIL POUR PARTICIPER !
          </div>

          {/* Spacing */}
          <div style={{ height: 32 }} />

          {/* Email input */}
          <FormField
            formKey={"mail"}
            required={true}
            handleFormValueChange={handleFormValueChange}
            label={"Votre adresse email *"}
          />

          {/* Spacing */}
          <div style={{ height: 32 }} />

          <FileInput
            formKey={"file"}
            required={true}
            handleFormValueChange={handleFormValueChange}
            label={"J'envoie ma preuve d'achat *"}
          />

          <div style={{ height: 32 }} />

          <CheckBox
            handleFormValueChange={handleFormValueChange}
            label={
              <div>
                J'accepte le{" "}
                <a
                  href="/boss-marionnaud-reglement.pdf"
                  target="_blank"
                  rel="noreferrer"
                >
                  règlement du jeu
                </a>
              </div>
            }
            formKey={"conditions"}
          />

          <FieldsetText>* champs obligatoires</FieldsetText>


          {/* Spacing */}
          <div style={{ height: 16 }} />

          {submitCnt > 0 && (
            <div className={"errors"}>
              <ul>
                {!isMailValid() && (
                  <li>Votre adresse email n'est pas valide</li>
                )}
                {!formValues.conditions && (
                  <li>Vous devez accepter le règlement</li>
                )}
                {emailVerified === "no" && (
                  <li>Vous avez déjà participé avec cet email</li>
                )}
                {!formValues.file && (
                  <li>Vous devez envoyer une preuve d'achat</li>
                )}
              </ul>
            </div>
          )}

          <ButtonWrapper>
            <Button text={"JE VALIDE"} handleClick={() => handleSubmit()} />
          </ButtonWrapper>
        </BlueBox>
      )}
      {waitingValidation && emailVerified === "yes" && (
        <div>
          <BlueBox>
            <div style={{ height: 16 }} />
            <Text>
            Vous allez recevoir<br/>un email avec un lien<br/>pour finaliser<br/>votre participation<br/>au tirage au sort !
              <br />
              <br />
              N'oubliez pas de<br/>vérifier vos courriers<br/>indésirables&nbsp;!
            </Text>
            <div style={{ height: 32 }} />
          </BlueBox>
          <ImageWrapper>
            <img
              className={"bottle-img"}
              src={"bottle.png"}
              alt={"bouteille de parfum boss"}
            />
          </ImageWrapper>
        </div>
      )}
    </ScreenWrapper>
  );
}

const ButtonWrapper = styled.div`
  margin: 0 auto -16px;
  max-width: 230px;
  transform: translateY(50%);
`;

const ImageWrapper = styled.div`
  margin: -32px auto 0;
  position: relative;
  z-index: 2;

  max-width: min(13vh, 32vw);

  img {
    aspect-ratio: 106/244;
    width: 100%;
  }
`;

const Text = styled.p`
  margin: 0;
  padding: 0 0.5em;
  font-size: min(2.5vh, clamp(12px, 10vw, 20px));

  // media query aspect ratio iphone SE
  @media screen and (max-width: 425px) and (min-aspect-ratio: 370/670) {
    font-size: 4.2vw;
  }
`;

const FieldsetText = styled.small`
  font-size: min(1.5vw, 8px);
  display: block;
  text-align: left;
  margin-top: 2rem;
  text-transform: uppercase;
`;